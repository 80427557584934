<template>
  <b-card>
    <FormView
      :data="$store.getters['multipleSection/detailData']"
      :fields="fields"
      @load="loadData"
    />
  </b-card>
</template>

<script>
import FormView from '@/layouts/components/FormView.vue'
import fields from '@/field/multiple-section'

import {
  BCard,
} from 'bootstrap-vue'

export default {
  components: {
    BCard,
    FormView,
  },
  data() {
    return {
      // Fields
      fields,
    }
  },
  methods: {
    loadData(id) {
      return this.$store.dispatch('multipleSection/getDetail', id)
    },
  },
}
</script>

  <style scoped>

  </style>
